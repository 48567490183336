import React, {useState} from 'react';
import PreviewCanvas from './PreviewCanvas.jsx';

const PreviewFeature = ({feat, onFeatureChange, initialValue}) => {

    const updateFeature = (feat) => {
        onFeatureChange(feat);
    }

    return (
<label key={feat}><input type="checkbox" checked={initialValue} onChange={updateFeature} /> {feat}</label>
    )
}

export default PreviewFeature;