import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import fontkit from 'fontkit';
import blobToBuffer from 'blob-to-buffer';
import VariationSelector from './VariationSelector.jsx';

const App = () => {
  const [font, setFont] = useState();

  useEffect(() => {
    const loadFont = async () => {
      const fontFile = await fetch('/assets/fonts/variable/Llawen-VF.ttf');
      const fontBlob = await fontFile.blob();
      console.log('fontBlob', fontBlob);
      blobToBuffer(fontBlob, (err, buffer) => {
        setFont(fontkit.create(buffer));
      });
    };
    loadFont();
  }, []);

  return (
    <div className="app">
      <h1>Llawen - variable font demo</h1>
      {font &&
        <VariationSelector font={font} />
      }
    </div>
  )
};

const root = ReactDOM.createRoot(document.getElementById('app-container'));
root.render(<App />);
